<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
      style="align-items: center"
    >
      <v-row>
        <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
          <b>{{ $t("patrimonial_infos") }}</b>
        </p>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <span>
            {{ $t("total_assets") }}
          </span>
        </v-col>
      </v-row>
      <v-row
        style="width: 100%; max-width: 300px"
        justify="center"
        class="totalAssetsRow d-inline-flex justify-center"
        :class="isMobile() ? 'mx-0' : 'mx-10'"
      >
        <v-col cols="4" class="py-0 pr-0">
          <v-select
            :rules="[required]"
            v-model="User.CurrencyPreference"
            :items="currencyOptions"
            item-text="text"
            item-value="value"
            outlined
            dense
            class="portfolio-size-currency-input"
            color="primary"
            data-test="Alpha:PatrimonialInfo:SelectCurrencyPreference"
          ></v-select>
        </v-col>
        <v-col cols="8" class="py-0">
          <money
            :prefix="getCurrencyPrefix(User.CurrencyPreference)"
            thousands="."
            decimal=","
            min="1"
            :rules="[(v) => (!!v && v != 0) || required()]"
            :class="[User.TotalAssets > 0 ? 'field' : 'fieldRequired']"
            v-model="User.TotalAssets"
            data-test="complete_profile:total_assets"
            class="pl-2"
          >
          </money>
          <span
            v-if="this.User.TotalAssets == 0"
            style="font-size: 13px; display: flex; color: red"
            >{{ $t("required") }}</span
          >
        </v-col>
      </v-row>

      <v-row
        ><v-col cols="12">
          <span>
            {{ $t("assets_origins") }}
          </span>
        </v-col></v-row
      >
      <div class="d-inline-flex justify-center">
        <div class="mt-4 mx-12">
          <v-row align="center" v-for="(asset, i) in assetOptions" :key="i">
            <v-checkbox
              color="primary"
              class="my-0 py-0"
              v-model="markedOptions"
              :value="asset.id"
              :rules="[(v) => v.length > 0 || required()]"
              data-test="complete_profile:asset_origins"
            >
              <template v-slot:label>
                <span style="color: var(--dark)">{{ $t(asset.key) }}</span>
              </template></v-checkbox
            >
          </v-row>
        </div>
      </div>

      <v-row justify="center" class="patrimonial-info-buttons">
        <v-btn
          outlined
          class="btn_back mr-1"
          color="primary"
          :loading="loading"
          @click="$emit('back')"
          data-test="complete_profile:btn_back_patrimonial_info"
          ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
        >
        <v-btn
          type="submit"
          color="primary"
          :loading="loading"
          class="btn_next ml-1"
          data-test="complete_profile:btn_next_patrimonial_info"
          ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
        >
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}

.btn_back {
  width: 125px !important;
  height: 42px !important;
}

.btn_next {
  width: 125px !important;
  height: 42px !important;
}

.fieldRequired {
  outline: none !important;
  width: 225px;
  box-sizing: border-box;
  border-style: solid !important;
  border-bottom-left-radius: 10px !important;
  height: 40px;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-color: red !important;
}

.field {
  outline: none !important;
  width: 225px;
  box-sizing: border-box;
  border-style: solid !important;
  border-bottom-left-radius: 10px !important;
  height: 40px;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border: 1px solid #aaaaaa;
  color: var(--dark);
}

.field:focus {
  border-width: 2px;
  border-color: var(--primary) !important;
}

.patrimonial-info-buttons {
  margin-top: 16px;
}

@media (max-width: 769px) {
  .fieldRequired {
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .totalAssetsRow {
    width: 225px;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import { getCurrencyPrefix } from "@/shared/helpers/currencyHelper";

export default {
  name: "ProfilePatrimonialInfo",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    valid: true,
    lazy: false,
    alert: null,
    error: null,
    assetOptions: [],
    markedOptions: [],
    localTotalAssets: null,
    CurrencyEnum: CurrencyEnum,
    getCurrencyPrefix,
    currencyOptions: [
      {
        text: "R$",
        value: CurrencyEnum.BRL,
      },
      {
        text: "US$",
        value: CurrencyEnum.USD,
      },
    ],
  }),
  async created() {
    await this.getAssetsOptions();

    // verificando a currency de acordo com a nacionalidade
    if (this.User.Nationality === "Brazil") {
      this.User.CurrencyPreference = this.CurrencyEnum.BRL;
    } else {
      this.User.CurrencyPreference = this.CurrencyEnum.USD;
    }

    // caso já tenha selecionado algum checkbox
    if (this.User.AssetResources.length) {
      this.markedOptions = this.User.AssetResources;
    }
  },
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },

  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        if (this.User.TotalAssets > 0) {
          this.User.AssetResources = this.markedOptions;

          if (typeof this.User.TotalAssets == "string") {
            let localTotalAssets;

            this.User.TotalAssets = parseFloat(localTotalAssets);
          }
          this.$emit("next");
        }
      }
    },
    async getAssetsOptions() {
      await this.apiService
        .getRequest("assetresource/list")
        .then((result) => {
          this.assetOptions = result.content;
        })
        .catch(() => {
          this.$toast.error(this.$t("error_occoured"));
        });
    },
  },
};
</script>
