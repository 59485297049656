<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
      v-if="render"
    >
      <v-row>
        <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
          <b>{{ $t("professional_info") }}</b>
        </p>
      </v-row>
      <v-row :class="gs.isMobile() ? 'mx-0' : 'mx-10'">
        <v-col
          style="text-align: left"
          cols="12"
          xl="5"
          lg="5"
          md="5"
          sm="5"
          class="py-0"
        >
          <p class="ml-2 mb-2">
            {{ $t("profession") }}
          </p>
          <div class="tooltip-container">
            <img
              src="../../../../assets/dxa-icon-tooltip_24px.svg"
              alt="tooltip"
              class="tooltip-img"
              @click="openTooltip"
              data-tooltip="tooltip-img"
            />
            <div class="tooltip-box-text" v-show="tooltip_visible">
              <p class="tooltip-text" data-tooltip="tooltip-text">
                {{ $t("profession_info_txt") }}
              </p>
            </div>
          </div>
          <v-autocomplete
            color="primary"
            :filter="filterObject"
            v-model="User.ProfessionId"
            :items="profession_options"
            :item-text="translateProfessionOptions"
            item-value="id"
            :rules="[required]"
            :label="$t('your_profession')"
            dense
            class="field"
            outlined
            @keyup="getProfession"
            data-test="complete_profile:profession"
            id="profession_input"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{
                    profession_text_follback
                      ? $t("write_your_profession")
                      : $t("profession_not_found")
                  }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <div class="my-2">
        <v-row :class="gs.isMobile() ? 'mx-0' : 'mx-10'">
          <v-col
            style="text-align: left"
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="6"
            class="py-0"
          >
            <p class="ml-2 mb-2">
              {{ $t("employer_type") }}
            </p>
            <v-select
              color="primary"
              class="field"
              :items="employer_type_options"
              item-text="text"
              item-value="value"
              :rules="[required]"
              v-model="User.EmployerType"
              @change="check_employer_type"
              outlined
              dense
              data-test="complete_profile:employer_type"
            >
            </v-select>
          </v-col>
          <v-col
            style="text-align: left"
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="6"
            display:block
            class="py-0"
          >
            <p class="ml-2 mb-2">
              {{ $t("professional_occupation") }}
            </p>

            <v-text-field
              class="field"
              color="primary"
              outlined
              dense
              :disabled="User.EmployerType == employerTypeEnum.NO_EMPLOYER"
              :rules="[required]"
              v-model="User.ProfessionalOccupation"
              data-test="complete_profile:professional_occupation"
            />
          </v-col>
        </v-row>
      </div>
      <div class="my-2">
        <v-row :class="gs.isMobile() ? 'mx-0' : 'mx-10'">
          <v-col
            style="text-align: left"
            cols="12"
            xl="5"
            lg="5"
            md="5"
            sm="5"
            display:block
            class="py-0"
          >
            <p class="ml-2 mb-2">
              {{ $t("company_id") }}
            </p>
            <!-- National Employer -->
            <v-text-field
              v-if="User.EmployerType === employerTypeEnum.NATIONAL_EMPLOYER"
              class="field"
              color="primary"
              outlined
              :disabled="User.EmployerType == employerTypeEnum.NO_EMPLOYER"
              @keyup="checkCNPJ"
              @keypress="handleOnlyNumber"
              dense
              v-mask="einMask"
              :rules="[
                invalidCnpj === true
                  ? customCnpjRule()
                  : (v) => (!!v && v.length === 18) || required(),
              ]"
              v-model="User.EmployerNumber"
              data-test="complete_profile:company_id"
            />
            <!-- Foreign Employer -->
            <v-text-field
              v-else
              class="field"
              color="primary"
              outlined
              :disabled="User.EmployerType == employerTypeEnum.NO_EMPLOYER"
              @keyup="checkCNPJ"
              @keypress="handleOnlyNumber"
              dense
              v-mask="einMask"
              :rules="[
                (v) => (!!v && v.length >= 1 && v.length <= 20) || required(),
              ]"
              v-model="User.EmployerNumber"
              data-test="complete_profile:company_id"
            />
          </v-col>

          <v-col
            style="text-align: left"
            cols="12"
            xl="7"
            lg="7"
            md="7"
            sm="7"
            display:block
            class="py-0"
          >
            <p class="ml-2 mb-2">
              {{ $t("employer") }}
            </p>

            <v-text-field
              class="field"
              color="primary"
              outlined
              dense
              :disabled="User.EmployerType == employerTypeEnum.NO_EMPLOYER"
              :rules="[required]"
              v-model="User.Employer"
              data-test="complete_profile:employer"
            />
          </v-col>
        </v-row>
      </div>
      <div class="my-2">
        <v-row :class="gs.isMobile() ? 'mx-0' : 'mx-10'">
          <v-col
            style="text-align: left"
            cols="12"
            xl="5"
            lg="5"
            md="5"
            sm="5"
            display:block
            class="py-0"
          >
            <p class="ml-2 mb-2">
              {{ $t("phone") }}
            </p>
            <v-text-field
              class="field"
              color="primary"
              outlined
              v-mask="[phoneMask]"
              dense
              :disabled="User.EmployerType == employerTypeEnum.NO_EMPLOYER"
              :rules="[required]"
              v-model="User.EmployerPhone"
              data-test="complete_profile:phone"
          /></v-col>

          <v-col
            style="text-align: left"
            cols="12"
            xl="7"
            lg="7"
            md="7"
            sm="7"
            display:block
            class="py-0"
          >
            <p class="ml-2 mb-2">
              {{ $t("monthly_income") }}
            </p>
            <v-text-field
              class="field"
              color="primary"
              outlined
              v-money="money"
              dense
              :disabled="User.EmployerType == employerTypeEnum.NO_EMPLOYER"
              :rules="[required]"
              v-model="localMonthtlyIncome"
              data-test="complete_profile:monthly_income"
              @input="updateMonthlyIncome"
            />
          </v-col>
        </v-row>
      </div>

      <v-row class="my-4" :class="gs.isMobile() ? 'mx-0' : 'mx-10'">
        <v-col cols="12" md="12" class="py-0" style="text-align: left">
          <p class="ml-2 mb-2">
            {{ $t("address") }}
          </p>
          <v-text-field
            class="field"
            color="primary"
            outlined
            dense
            :disabled="User.EmployerType == employerTypeEnum.NO_EMPLOYER"
            :rules="[required]"
            v-model="User.EmployerAddress"
            data-test="complete_profile:address_employer"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn
          outlined
          class="btn_back mr-1"
          color="primary"
          :loading="loading"
          @click="$emit('back')"
          data-test="complete_profile:btn_back_professional_info"
          ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
        >
        <v-btn
          type="submit"
          color="primary"
          :disabled="next_btn_disabled"
          :loading="loading"
          class="btn_next ml-1 mr-0"
          @click="checkCNPJ"
          data-test="complete_profile:btn_next_professional_info"
          ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
        >
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}

.btn_back {
  width: 125px !important;
  height: 42px !important;
}

.btn_next {
  width: 125px !important;
  height: 42px !important;
}

.field {
  box-sizing: border-box;
  border-radius: 10px;
  color: var(--primary);
}
</style>

<style scoped>
.tooltip-container {
  position: absolute;
  top: 55px !important;
  left: 168px;
  z-index: 100;
  display: flex;
  gap: 0.5rem;
}

.tooltip-img {
  width: 12px;
  height: 12px;
  cursor: pointer;
  display: block;
}

.tooltip-text {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.15px;
  color: var(--white);
  max-width: 193px;
  padding: 12px 12px 0 12px;
}

/* animation */
@keyframes show-tooltip {
  from {
    opacity: 0;
    transform: translate3d(-30px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.tooltip-box-text {
  background: #9c9c9c;
  border-radius: 6px;
  transition: 0.3s;
  animation: show-tooltip 0.4s forwards;
}

@media only screen and (max-width: 960px) {
  .tooltip-container {
    top: 67px;
    left: 130px;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import axios from "axios";
import { VMoney } from "v-money";
import { permissionOnlyNumber } from "@/shared/helpers/inputHelpers.js";
import { employerTypeEnum } from "../enums/ProfileEnum";

export default {
  name: "ProfileProfessionalInfo",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    localMonthtlyIncome: null,

    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$",
      precision: 2,
      masked: false,
    },
    directives: { money: VMoney },
    loading: false,
    valid: true,
    lazy: false,
    alert: null,
    error: null,
    fields_loading: false,
    render: true,
    einMask: null,
    phoneMask: null,
    isForeigner: false,
    profession_options: [],
    tooltip_visible: false,
    profession_text_follback: true,
    invalidCnpj: false,
    employerTypeEnum,
  }),
  async created() {
    this.getProfession();
    this.check_employer_type();
    this.checkEinMask();
    this.checkNationality();
    if (this.User.EmployerNumber != null) {
      this.checkCNPJ();
    }
    if (this.User.MonthlyIncome > 0) {
      this.localMonthtlyIncome = this.User.MonthlyIncome.toFixed(2);
    }
  },

  props: {
    User: Object,
  },
  computed: {
    customCnpjRule() {
      return (value) => this.$t("invalid_cnpj");
    },
    employer_type_options() {
      return [
        {
          text: this.$t("nacional_employer"),
          value: employerTypeEnum.NATIONAL_EMPLOYER,
        },
        {
          text: this.$t("foreign_employer"),
          value: employerTypeEnum.FOREIGN_EMPLOYER,
        },
        {
          text: this.$t("have_no_employer"),
          value: employerTypeEnum.NO_EMPLOYER,
        },
      ];
    },
    next_btn_disabled() {
      return (
        (this.User.EmployerType === employerTypeEnum.NATIONAL_EMPLOYER ||
          this.User.EmployerType === null ||
          this.User.ProfessionId === 0 ||
          this.User.ProfessionId === null) &&
        (!this.User.ProfessionalOccupation ||
          (this.User.EmployerType === employerTypeEnum.NATIONAL_EMPLOYER &&
            this.invalidCnpj === true) ||
          !this.User.ProfessionId ||
          !this.User.EmployerNumber ||
          !this.User.Employer ||
          !this.User.EmployerPhone ||
          !this.User.MonthlyIncome ||
          !this.User.EmployerAddress)
      );
    },
    required() {
      return (value) =>
        this.User.EmployerType > 0 ||
        this.User.ProfessionId > 0 ||
        value != null ||
        this.$t("required");
    },
  },
  watch: {
    "User.EmployerType"() {
      // limpado o CNPJ e os campos autocomplete do CNPJ, assim que troca o tipo de empregador.
      setTimeout(() => {
        this.User.EmployerNumber = null;
        this.User.Employer = null;
        this.User.EmployerPhone = null;
        this.User.EmployerAddress = null;
      }, [100]);
      this.$forceUpdate();
    },
  },
  methods: {
    check_employer_type() {
      this.isForeigner = false;
      if (this.User.EmployerType == employerTypeEnum.FOREIGN_EMPLOYER) {
        this.isForeigner = true;
      }
      this.checkEinMask();
    },
    checkNationality() {
      if (this.User.Nationality === "Brazil") {
        this.phoneMask = "(##) #####-####";
      } else {
        this.phoneMask = "############";
      }
    },
    checkEinMask() {
      if (this.User.EmployerType === employerTypeEnum.NATIONAL_EMPLOYER) {
        this.einMask = "##.###.###/####-##";
      } else {
        // max 20 caracteres
        this.einMask = "####################";
      }
    },
    checkForm: function () {
      var result = this.$refs.form.validate();

      if (result) {
        this.$emit("next");
      }
    },
    handleOnlyNumber(event) {
      if (permissionOnlyNumber(event)) {
        event.preventDefault();
      }
    },
    async checkCNPJ() {
      this.invalidCnpj = false;

      // National Employer length > 18
      if (this.EmployerType === employerTypeEnum.NATIONAL_EMPLOYER) {
        if (
          this.User.EmployerNumber.length > 1 &&
          this.User.EmployerNumber.length < 18
        ) {
          this.invalidCnpj = true;
          return;
        }
      }

      if (this.User.EmployerType < 2) {
        if (
          this.User.EmployerType === employerTypeEnum.NATIONAL_EMPLOYER &&
          this.User.EmployerNumber &&
          this.User.EmployerNumber.length === 18
        ) {
          let cnpj = this.User.EmployerNumber.replace(/\//g, "")

            .replace(/\./g, "")
            .replace(/\-/g, "");

          axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
          await this.apiService
            .getRequest(`health/cnpj/${cnpj}`, false)
            .then((resp) => {
              resp = JSON.parse(resp.resp);

              if (resp.logradouro && resp.numero) {
                this.User.EmployerAddress = resp.logradouro + "," + resp.numero;
              }

              if (resp.nome) {
                this.User.Employer = resp.nome;
              }

              if (resp.telefone) {
                this.User.EmployerPhone = resp.telefone;
              }
            })
            .catch((err) => {
              if (err.status === 400 && err.body === "cnpj_invalid") {
                this.invalidCnpj = true;
              }
            });
        }
      }
    },
    async getProfession() {
      const professionInput = document.getElementById("profession_input");
      const professionValue =
        (!professionInput || !professionInput.value) && this.User.Profession
          ? this.User.Profession
          : professionInput.value;
      const professionLength = professionValue.length;
      const language = localStorage.getItem("lang");

      if (professionValue.length >= 3) {
        await this.apiService
          .getRequest(`profession/${language}/${professionValue}`)
          .then((result) => {
            this.profession_options = result.content;
          })
          .catch(() => {
            this.$toast.error(this.$t("error_occoured"));
          });
      }

      if (professionLength < 3) {
        this.profession_text_follback = true;
      } else {
        this.profession_text_follback = false;
      }
    },
    filterObject(item, queryText, itemText) {
      let translatedWord = this.$t(item.key);

      return (
        translatedWord
          .toLocaleLowerCase()
          .indexOf(itemText.toLocaleLowerCase()) > -1
      );
    },
    openTooltip() {
      if (this.tooltip_visible) {
        this.tooltip_visible = false;
      } else {
        this.tooltip_visible = true;
        window.addEventListener("click", this.closeTooltip);
      }
    },
    closeTooltip({ target }) {
      const tooltipImg = document.querySelector("[data-tooltip='tooltip-img']");
      const tooltipText = document.querySelector(
        "[data-tooltip='tooltip-text']"
      );

      if (!target.contains(tooltipImg) && !target.contains(tooltipText)) {
        this.tooltip_visible = false;
        window.removeEventListener("click", this.closeTooltip);
      }
    },

    translateProfessionOptions(item) {
      this.User.Profession = item.key;
      return this.$t(item.key);
    },

    updateMonthlyIncome() {
      let valorMes = this.localMonthtlyIncome.replace("R$", "");
      valorMes = valorMes.replaceAll(".", "");
      valorMes = valorMes.replace(",", ".");
      this.User.MonthlyIncome = parseFloat(valorMes);
    },
  },
};
</script>
