export const profileTabsEnums = {
  UserData: 0,
  UserPortfolioPreferences: 1,
  UserFiles: 2,
  UserStatements: 3
};

export const verifyProfileEnums = {
  VERIFY_ACCOUNT: 0,
  SUCCESSFULLY_VERIFIED: 1,
};

export const employerTypeEnum = {
  NATIONAL_EMPLOYER: 0,
  FOREIGN_EMPLOYER: 1,
  NO_EMPLOYER: 2,
};
