<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row>
        <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
          <b>{{ $t("legal_representative_header") }}</b>
        </p>
      </v-row>
      <div style="text-align: left">
        <v-row :class="isMobile() ? 'mx-0' : 'mx-10'">
          <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("name") }}
            </p>
            <v-text-field
              color="primary"
              outlined
              v-on:keypress="isLetter($event)"
              class="field"
              dense
              :rules="[required]"
              v-model="User.LegalRepresentative.Name"
            />
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("cpf") }}
            </p>
            <v-text-field
              color="primary"
              outlined
              class="field"
              v-mask="'###.###.###-##'"
              dense
              :rules="[(v) => (!!v && v.length == 14) || required()]"
              v-model="User.LegalRepresentative.SocialNumber"
            />
          </v-col>

          <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("document_type") }}
            </p>
            <v-autocomplete
              center
              dense
              :no-data-text="$t('not_found')"
              class="field"
              outlined
              :items="docType"
              :rules="[requiredNotZero]"
              :item-text="translateDocType"
              item-value="value"
              v-model="User.LegalRepresentative.LinkType"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </div>
      <div style="text-align: left">
        <v-row :class="isMobile() ? 'mx-0' : 'mx-10'">
          <v-col cols="6" xl="4" lg="4" md="4" sm="4" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("nationality") }}
            </p>
            <v-text-field
              color="primary"
              outlined
              v-on:keypress="isLetter($event)"
              class="field"
              dense
              :rules="[required]"
              v-model="User.LegalRepresentative.Nationality"
            />
          </v-col>

          <v-col cols="6" xl="4" lg="4" md="4" sm="4" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("marital_status") }}
            </p>
            <v-autocomplete
              center
              outlined
              :no-data-text="$t('not_found')"
              class="field"
              dense
              :rules="[required]"
              :items="maritalStatus"
              :item-text="translateMaritalStatus"
              item-value="value"
              v-model="User.LegalRepresentative.MaritalStatus"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("profession") }}
            </p>
            <v-text-field
              color="primary"
              outlined
              v-on:keypress="isLetter($event)"
              dense
              class="field"
              :rules="[required]"
              v-model="User.LegalRepresentative.Profession"
            />
          </v-col>
        </v-row>
      </div>
      <div style="text-align: left">
        <v-row :class="isMobile() ? 'mx-0' : 'mx-10'">
          <v-col cols="8" xl="4" lg="4" md="4" sm="4" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("document_type") }}
            </p>
            <v-autocomplete
              dense
              outlined
              :no-data-text="$t('not_found')"
              class="field"
              @change="docTypeSelector"
              center
              :rules="[required]"
              :items="personalDocType"
              :item-text="translatePersonalDocType"
              item-value="value"
              v-model="User.LegalRepresentative.DocumentType"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </div>
      <div style="text-align: left">
        <v-row :class="isMobile() ? 'mx-0' : 'mx-10'">
          <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("document_number") }}
            </p>
            <v-text-field
              color="primary"
              outlined
              class="field"
              :disabled="disabled_field"
              v-mask="[currentMaskDocType]"
              :rules="[
                (v) => (!!v && v.length >= numberOfCharacters) || required(),
              ]"
              dense
              v-model="User.LegalRepresentative.DocumentNumber"
            />
          </v-col>

          <v-col cols="12" xl="5" lg="5" md="5" sm="5" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("issuing_body") }}
            </p>
            <v-text-field
              color="primary"
              outlined
              class="field"
              :disabled="disabled_field"
              v-on:keypress="isLetter($event)"
              dense
              :rules="[required]"
              v-model="User.LegalRepresentative.Issuer"
            />
          </v-col>
        </v-row>
      </div>
      <div style="text-align: left">
        <v-row :class="isMobile() ? 'mx-0' : 'mx-10'">
          <v-col cols="6" xl="4" lg="4" md="4" sm="4" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("state") }}
            </p>
            <v-autocomplete
              color="primary"
              outlined
              :no-data-text="$t('not_found')"
              :disabled="disabled_field"
              :items="states"
              item-text="Nome"
              item-value="Sigla"
              class="field"
              dense
              :rules="[required]"
              v-model="User.LegalRepresentative.IssuerState"
            />
          </v-col>

          <v-col cols="6" xl="3" lg="3" md="3" sm="3" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("expedition_date") }}
            </p>
            <v-menu
              ref="menuDate"
              v-model="menuDate"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  :label="$t('date')"
                  prepend-inner-icon="mdi-calendar"
                  class="field pt-0 mt-0 mb-1"
                  outlined
                  :disabled="disabled_field"
                  dense
                  :rules="[required]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                ></v-text-field>
              </template>
              <v-date-picker
                class="mx-auto"
                v-model="date"
                @change="
                  $refs.menuDate.save(date);
                  menuDate = false;
                "
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <div style="text-align: left">
        <v-row :class="isMobile() ? 'mx-0' : 'mx-10'">
          <v-col class="py-0">
            <p>
              {{ $t("non_resident_investor_question") }}
            </p>
          </v-col>
        </v-row>
        <v-row :class="isMobile() ? 'mx-0' : 'mx-10'">
          <v-radio-group center row v-model="User.HasNonResidentInvestor">
            <v-col class="py-0">
              <v-radio :value="false">
                <template v-slot:label>
                  <span style="color: var(--dark)">{{ $t("no") }}</span>
                </template></v-radio
              >
            </v-col>
            <v-col class="py-0">
              <v-radio :value="true">
                <template v-slot:label>
                  <span style="color: var(--dark)">{{ $t("yes") }}</span>
                </template></v-radio
              >
            </v-col>
          </v-radio-group>
        </v-row>
      </div>

      <v-row justify="center">
        <v-btn
          outlined
          class="btn_back mr-1"
          color="primary"
          :loading="loading"
          @click="$emit('back')"
          ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
        >
        <v-btn
          type="submit"
          color="primary"
          :loading="loading"
          class="btn_next ml-1"
          ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
        >
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.field {
  box-sizing: border-box;
  border-radius: 10px;
  color: var(--primary);
}
.btn_back {
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  width: 125px !important;
  height: 42px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import StatesName from "./states.json";
export default {
  name: "LegalRepresentativeInfo",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    states: StatesName,
    loading: false,
    valid: true,
    lazy: false,
    menuDate: null,
    disabled_field: true,
    date: null,
    alert: null,
    error: null,
    numberOfCharacters: 12,
    currentMaskDocType: null,
    docType: [
      { text: "legal_representative", value: 0 },
      { text: "attorney", value: 1 },
    ],
    maritalStatus: [
      { text: "single", value: 1 },

      { text: "married", value: 2 },

      { text: "widower", value: 3 },

      { text: "divorced", value: 4 },
    ],
    personalDocType: [
      {
        text: "rg",

        value: 1,
      },
      {
        text: "cnh",
        value: 2,
      },
      {
        text: "passport",
        value: 3,
      },
    ],
  }),
  computed: {},
  async created() {
    if (!this.User.LegalRepresentative) {
      this.User.LegalRepresentative = {};
    }

    if (this.User.LegalRepresentative.ExpeditionDate) {
      this.date = this.User.LegalRepresentative.ExpeditionDate.split("T")[0];
    }
    if (this.User.LegalRepresentative.DocumentType == 0) {
      this.numberOfCharacters = 0;
      this.currentMaskDocType = "";
    } else if (this.User.LegalRepresentative.DocumentType == 1) {
      this.disabled_field = false;
      this.numberOfCharacters = 8;
      this.currentMaskDocType = "###########";
    } else if (this.User.LegalRepresentative.DocumentType == 2) {
      this.numberOfCharacters = 11;
      this.disabled_field = false;
      this.currentMaskDocType = "###########";
    } else if (this.User.LegalRepresentative.DocumentType == 3) {
      this.numberOfCharacters = 8;
      this.disabled_field = false;
      this.currentMaskDocType = "AA######";
    }
  },
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    requiredNotZero() {
      return (value) => !!value || value === 0 || this.$t("required");
    },
  },
  methods: {
    docTypeSelector() {
      if (this.User.LegalRepresentative.DocumentType == "") {
        this.numberOfCharacters = 0;
        this.currentMaskDocType = "";
      } else if (this.User.LegalRepresentative.DocumentType == 1) {
        this.numberOfCharacters = 8;
        this.disabled_field = false;
        this.currentMaskDocType = "############";
      } else if (this.User.LegalRepresentative.DocumentType == 2) {
        this.numberOfCharacters = 11;
        this.disabled_field = false;
        this.currentMaskDocType = "###########";
      } else if (this.User.LegalRepresentative.DocumentType == 3) {
        this.numberOfCharacters = 8;
        this.disabled_field = false;
        this.currentMaskDocType = "AA######";
      }
    },
    translatePersonalDocType(item) {
      return this.$t(item.text);
    },
    translateMaritalStatus(item) {
      return this.$t(item.text);
    },
    translateDocType(item) {
      return this.$t(item.text);
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (
        /^[A-Za-z]+$/.test(char) ||
        char == "ç" ||
        char == "Ç" ||
        char == "á" ||
        char == "Á" ||
        char == "é" ||
        char == "É" ||
        char == "í" ||
        char == "Í" ||
        char == "ó" ||
        char == "Ó" ||
        char == "ú" ||
        char == "Ú" ||
        char == "ó" ||
        char == "Ó" ||
        char == "â" ||
        char == "Â" ||
        char == "ê" ||
        char == "Ê" ||
        char == "î" ||
        char == "Î" ||
        char == "ô" ||
        char == "Ô" ||
        char == "û" ||
        char == "Û" ||
        char == "ã" ||
        char == "Ã" ||
        char == "õ" ||
        char == "Õ" ||
        char == " "
      )
        return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        this.User.LegalRepresentative.ExpeditionDate =
          this.date.toString() + "T00:00:00";
        this.$emit("next");
      }
    },
  },
};
</script>
