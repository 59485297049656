<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row>
        <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
          <b>{{ $t("bank_info") }}</b>
        </p>
      </v-row>

      <ManageBankAccountComponent
        @changeForegin="resetBankData"
        :BankAccount="user_bank_accounts"
        v-if="bankload"
      />

      <v-row justify="center" class="bank-info-buttons">
        <v-btn
          outlined
          class="btn_back mr-1"
          color="primary"
          :loading="loading"
          @click="$emit('back')"
          data-test="complete_profile:btn_back_bank_info"
          ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
        >
        <v-btn
          type="submit"
          color="primary"
          :loading="loading"
          class="btn_next mr-1"
          data-test="complete_profile:btn_next_bank_info"
          ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
        >
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}

.btn_back {
  width: 135px !important;
  height: 42px !important;
}

.btn_next {
  width: 135px !important;
  height: 42px !important;
}

.bank-info-buttons {
  margin-top: 16px;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import ManageBankAccountComponent from "@/components/dxa/BankAccount/ManageBankAccount";
export default {
  name: "ProfileBankInfo",
  components: {
    ManageBankAccountComponent,
  },
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    valid: true,
    lazy: false,
    alert: null,
    error: null,
    user_bank_accounts: {},
    bankload: true,
  }),
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  async created() {
    await this.get_user_bank_account();
  },
  methods: {
    get_user_bank_account() {
      if (this.User.BankAccounts.length > 0) {
        this.loadNationalBankData();
      } else if (this.User.InterBankAccounts.length > 0) {
        this.loadForeignBankData();
      }
    },
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },

    resetBankData() {
      let isForeign = this.user_bank_accounts.IsForeign;
      if (
        this.User.BankAccounts.length > 0 ||
        this.User.InterBankAccounts.length > 0
      ) {
        this.bankload = false;
        setTimeout(() => {
          this.bankload = true;
        }, 10);

        this.user_bank_accounts = {};
        this.user_bank_accounts.IsForeign = isForeign;
        if (
          this.user_bank_accounts.IsForeign &&
          this.User.InterBankAccounts.length > 0
        ) {
          this.loadForeignBankData();
        } else if (
          !this.user_bank_accounts.IsForeign &&
          this.User.BankAccounts.length > 0
        ) {
          this.loadNationalBankData();
        }
      }
    },

    loadForeignBankData() {
      var intBank = this.User.InterBankAccounts[0];
      this.user_bank_accounts.Id = intBank.Id;
      this.user_bank_accounts.BankAccountId = intBank.InterBankAccount.Id;
      this.user_bank_accounts.Bank = intBank.InterBankAccount.Bank;
      this.user_bank_accounts.Account = intBank.InterBankAccount.Account;
      this.user_bank_accounts.Address = intBank.InterBankAccount.Address;
      this.user_bank_accounts.OtherInformation =
        intBank.InterBankAccount.OtherInformation;
      this.user_bank_accounts.Beneficiary = intBank.InterBankAccount.Recipient;
      this.user_bank_accounts.ABA = intBank.InterBankAccount.RoutingNumber;
      this.user_bank_accounts.SWIFT = intBank.InterBankAccount.Swift;
      this.user_bank_accounts.UserId = intBank.UserId;
      this.user_bank_accounts.IsForeign = true;
    },

    loadNationalBankData() {
      var bank = this.User.BankAccounts[0];
      this.user_bank_accounts.Id = bank.Id;
      this.user_bank_accounts.BankAccountId = bank.BankAccount.Id;
      this.user_bank_accounts.Bank = bank.BankAccount.Bank;
      this.user_bank_accounts.Agency = bank.BankAccount.Agency;
      this.user_bank_accounts.Account = bank.BankAccount.Account;
      this.user_bank_accounts.UserId = bank.UserId;
      this.user_bank_accounts.IsForeign = false;
    },

    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        if (this.user_bank_accounts.IsForeign) {
          this.User.InterBankAccounts = [
            {
              Id: this.user_bank_accounts.Id,
              InterBankAccount: {
                Bank: this.user_bank_accounts.Bank,
                Account: this.user_bank_accounts.Account,
                Recipient: this.user_bank_accounts.Beneficiary,
                Address: this.user_bank_accounts.Address,
                Swift: this.user_bank_accounts.SWIFT,
                RoutingNumber: this.user_bank_accounts.ABA,
                OtherInformation: this.user_bank_accounts.OtherInformation,
              },
            },
          ];
        } else {
          this.User.BankAccounts = [
            {
              Id: this.user_bank_accounts.Id,
              BankAccount: {
                Id: this.user_bank_accounts.BankAccountId,
                Bank: this.user_bank_accounts.Bank,
                Agency: this.user_bank_accounts.Agency,
                Account: this.user_bank_accounts.Account,
              },
            },
          ];
        }
        this.$emit("next");
      }
    },
  },
};
</script>
