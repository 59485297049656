<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row>
        <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
          <b>{{ $t("statements") }}</b>
        </p>
      </v-row>
      <div class="my-2">
        <span>
          {{ $t("is_politically_exposed") }}
        </span>
        <v-row>
          <v-col class="d-inline-flex justify-center">
            <v-radio-group
              row
              center
              v-model="User.PoliticallyExposed"
              data-test="complete_profile:politacally_exposed"
            >
              <v-radio :value="true">
                <template v-slot:label>
                  <span style="color: var(--dark)">{{ $t("yes") }}</span>
                </template></v-radio
              >
              <v-radio :value="false">
                <template v-slot:label>
                  <span style="color: var(--dark)">{{ $t("no") }}</span>
                </template></v-radio
              >
            </v-radio-group>
          </v-col>
        </v-row>
      </div>
      <div class="my-2">
        <span>
          {{ $t("is_us_person") }}
        </span>
        <v-row>
          <v-col class="d-inline-flex justify-center">
            <v-radio-group
              row
              center
              v-model="User.USPerson"
              data-test="complete_profile:us_person"
            >
              <v-radio :value="true">
                <template v-slot:label>
                  <span style="color: var(--dark)">{{ $t("yes") }}</span>
                </template></v-radio
              >
              <v-radio :value="false">
                <template v-slot:label>
                  <span style="color: var(--dark)">{{ $t("no") }}</span>
                </template></v-radio
              >
            </v-radio-group>
          </v-col>
        </v-row>
      </div>

      <v-row justify="center">
        <v-btn
          outlined
          class="btn_back mr-1"
          color="primary"
          :loading="loading"
          @click="$emit('back')"
          data-test="complete_profile:btn_back_statements"
          ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
        >
        <v-btn
          type="submit"
          color="primary"
          :loading="loading"
          class="btn_next ml-1"
          data-test="complete_profile:btn_next_statements"
          ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
        >
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.btn_back {
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  width: 125px !important;
  height: 42px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "ProfileStatements",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    valid: true,
    lazy: false,
    alert: null,
    error: null,
  }),
  async created() {},
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        this.$emit("next");
      }
    },
  },
};
</script>
