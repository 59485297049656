export const ComplienceStepsPFEnum = {
    REGISTEERINFO: 1,
    PERSONALDOCS: 2,
    CONTACTS: 3,
    PROFESSIONALINFO: 4,
    PATRIMONIALINFO: 5,
    STATEMENTS: 6,
    BANKINFO: 7,
    FINALDECLARATIONS: 8
  };
  
  export const ComplienceStepsPJEnum = {
    JURIDICALINFO: 1,
    BANKINFO: 2,
    LEGALREPRESENTATIVEINFO: 3,
    NONRESIDENTINVESTORINFO: 4,
    STOCKPOSITIONRESIDENT: 4,
    STOCKPOSITIONNONRESIDENT:5,
    EQUITYHOLDINGRESIDENT: 5,
    EQUITYHOLDINGNONRESIDENT:6,
    FINALDECLARATIONSRESIDENT:6,
    FINALDECLARATIONSNONRESIDENT:7

  };