<template>
  <v-container
    v-if="!loading"
    id="register"
    class="justify-center"
    tag="section"
  >
    <div class="text-center" style="width: 100%">
      <img src="../../../assets/dealboard-logo-black.svg" alt="Dealboard" />
    </div>
    <p class="text-center mt-5">{{ $t("cvm_required") }}</p>
    <v-row justify="center" align="center" class="mb-4 mx-1">
      <v-slide-y-transition appear>
        <v-card
          style="border-style: groove"
          light
          max-width="100%"
          width="1000"
          class="SecondCadasterCard px-5 py-3"
        >
          <v-card-text class="text-center">
            <v-row justify="center" v-if="loading">
              <v-progress-circular
                indeterminate
                size="70"
                color="primary"
              ></v-progress-circular>
            </v-row>
            <RegisterInfo
              v-else-if="
                step == ComplienceStepsPFEnum.REGISTEERINFO &&
                user.JuridicalType == JuridicalTypeEnum.NaturalPerson
              "
              @next="next_step"
              @back="previous_step"
              :User="user"
            ></RegisterInfo>
            <PersonalDocs
              v-else-if="
                step == ComplienceStepsPFEnum.PERSONALDOCS &&
                user.JuridicalType == JuridicalTypeEnum.NaturalPerson
              "
              @next="next_step"
              @back="previous_step"
              :User="user"
            ></PersonalDocs>
            <Contacts
              v-else-if="
                step == ComplienceStepsPFEnum.CONTACTS &&
                user.JuridicalType == JuridicalTypeEnum.NaturalPerson
              "
              @next="next_step"
              @back="previous_step"
              :User="user"
            ></Contacts>
            <ProfessionalInfo
              v-else-if="
                step == ComplienceStepsPFEnum.PROFESSIONALINFO &&
                user.JuridicalType == JuridicalTypeEnum.NaturalPerson
              "
              @next="next_step"
              @back="previous_step"
              :User="user"
            ></ProfessionalInfo>
            <PatrimonialInfo
              v-else-if="
                step == ComplienceStepsPFEnum.PATRIMONIALINFO &&
                user.JuridicalType == JuridicalTypeEnum.NaturalPerson
              "
              @next="next_step"
              @back="previous_step"
              :User="user"
            ></PatrimonialInfo>
            <Statements
              v-else-if="
                step == ComplienceStepsPFEnum.STATEMENTS &&
                user.JuridicalType == JuridicalTypeEnum.NaturalPerson
              "
              @next="next_step"
              @back="previous_step"
              :User="user"
            ></Statements>
            <BankInfo
              v-else-if="
                (step == ComplienceStepsPFEnum.BANKINFO &&
                  user.JuridicalType == JuridicalTypeEnum.NaturalPerson) ||
                (step == ComplienceStepsPJEnum.BANKINFO &&
                  user.JuridicalType == JuridicalTypeEnum.LegalPerson)
              "
              @next="next_step"
              @back="previous_step"
              :User="user"
            ></BankInfo>
            <JuridicalInfo
              v-else-if="
                step == ComplienceStepsPJEnum.JURIDICALINFO &&
                user.JuridicalType == JuridicalTypeEnum.LegalPerson
              "
              @next="next_step"
              @back="previous_step"
              :User="user"
            ></JuridicalInfo>
            <LegalRepresentativeInfo
              v-else-if="
                step == ComplienceStepsPJEnum.LEGALREPRESENTATIVEINFO &&
                user.JuridicalType == JuridicalTypeEnum.LegalPerson
              "
              @next="next_step"
              @back="previous_step"
              :User="user"
            >
            </LegalRepresentativeInfo>
            <NonResidentInvestorInfo
              v-else-if="
                step == ComplienceStepsPJEnum.NONRESIDENTINVESTORINFO &&
                user.JuridicalType == JuridicalTypeEnum.LegalPerson &&
                user.HasNonResidentInvestor
              "
              @next="next_step"
              @back="previous_step"
              :User="user"
            ></NonResidentInvestorInfo>
            <StockPosition
              v-else-if="
                (step == ComplienceStepsPJEnum.STOCKPOSITIONRESIDENT &&
                  user.JuridicalType == JuridicalTypeEnum.LegalPerson &&
                  !user.HasNonResidentInvestor) ||
                (step == ComplienceStepsPJEnum.STOCKPOSITIONNONRESIDENT &&
                  user.JuridicalType == JuridicalTypeEnum.LegalPerson &&
                  user.HasNonResidentInvestor)
              "
              @next="next_step"
              @back="previous_step"
              :User="user"
            ></StockPosition>
            <EquityHolding
              v-else-if="
                (step == ComplienceStepsPJEnum.EQUITYHOLDINGRESIDENT &&
                  user.JuridicalType == JuridicalTypeEnum.LegalPerson &&
                  !user.HasNonResidentInvestor) ||
                (step == ComplienceStepsPJEnum.EQUITYHOLDINGNONRESIDENT &&
                  user.JuridicalType == JuridicalTypeEnum.LegalPerson &&
                  user.HasNonResidentInvestor)
              "
              @next="next_step"
              @back="previous_step"
              :User="user"
            ></EquityHolding>
            <FinalDeclarations
              v-else-if="
                ((step == ComplienceStepsPJEnum.FINALDECLARATIONSRESIDENT ||
                  step == ComplienceStepsPJEnum.FINALDECLARATIONSNONRESIDENT) &&
                  user.JuridicalType == JuridicalTypeEnum.LegalPerson) ||
                (step == ComplienceStepsPFEnum.FINALDECLARATIONS &&
                  user.JuridicalType == JuridicalTypeEnum.NaturalPerson)
              "
              @next="finishProfile"
              @back="previous_step"
              :User="user"
            ></FinalDeclarations>
          </v-card-text>
        </v-card>
        <!-- </base-material-card> -->
      </v-slide-y-transition>
    </v-row>
    <v-row>
      <v-progress-linear
        class="mx-auto text-center"
        background-color="grey"
        color="primary"
        rounded
        style="max-width: 35%"
        height="7px"
        :value="
          (step * 100) /
          (user.JuridicalType == JuridicalTypeEnum.LegalPerson
            ? ComplienceStepsPJEnum.FINALDECLARATIONSRESIDENT
            : ComplienceStepsPFEnum.FINALDECLARATIONS)
        "
      ></v-progress-linear>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import moment from "moment";
import BankInfo from "./steps/BankInfo";
import Contacts from "./steps/Contacts";
import PatrimonialInfo from "./steps/PatrimonialInfo";
import PersonalDocs from "./steps/PersonalDocs";
import ProfessionalInfo from "./steps/ProfessionalInfo";
import RegisterInfo from "./steps/RegisterInfo";
import JuridicalInfo from "./steps/JuridicalInfo";
import LegalRepresentativeInfo from "./steps/LegalRepresentative";
import NonResidentInvestorInfo from "./steps/NonResidentInvestor";
import StockPosition from "./steps/StockPosition";
import EquityHolding from "./steps/EquityHolding";
import FinalDeclarations from "./steps/FinalDeclarations";
import Statements from "./steps/Statements";
import {
  ComplienceStepsPFEnum,
  ComplienceStepsPJEnum,
} from "@/shared/enums/ComplienceStepsEnum.js";
import { JuridicalTypeEnum } from "@/shared/enums/JuridicalType";
import Vue from "vue";

export default {
  name: "CompleteProfile",
  components: {
    BankInfo,
    Contacts,
    PatrimonialInfo,
    PersonalDocs,
    ProfessionalInfo,
    RegisterInfo,
    Statements,
    JuridicalInfo,
    LegalRepresentativeInfo,
    NonResidentInvestorInfo,
    StockPosition,
    EquityHolding,
    FinalDeclarations,
  },
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    step: 1,
    localMonthlyIncome: null,
    user: {
      Name: "",
      LastName: "",
      Email: "",
      EmployerType: null,
      UserPassword: "",
      DocumentType: 0,
      MaritalStatus: 0,
      JuridicalType: 0,
      HasLegalRepresentative: false,
      PoliticallyExposed: false,
      USPerson: false,
      LegalRepresentative: {},
      NonResidentInvestor: {},
      MonthlyIncome: 0,
      TotalAssets: null,
      EquityHoldings: [],
      StockPositions: [],
      Sex: 0,
      aux: false,
      Profession: null,
      ProfessionId: null,
    },
    JuridicalTypeEnum,
    accTypeDisabled: false,
    configs: null,
    ComplienceStepsPFEnum: ComplienceStepsPFEnum,
    ComplienceStepsPJEnum: ComplienceStepsPJEnum,
  }),
  async created() {
    await this.getFilledInfo();
    await this.get_basic_info();
  },
  computed: {},
  methods: {
    get_basic_info() {
      this.loading = true;
      this.apiService
        .getRequest("investor/basic-info")
        .then((resp) => {
          var user = JSON.parse(resp.message);
          if (user.ProfileDone) {
            this.$router.push("/profile/verify");
          }
          this.user.Country ||= user.Country;
          this.user.SocialNumber ||= user.SocialNumber;
          this.user.Nationality ||= user.Nationality;
          this.user.JuridicalType ||= user.JuridicalType;
          var numberPattern = /\d+/g;
          this.user.SocialNumber =
            this.user.SocialNumber.match(numberPattern).join("");
          if (
            (!this.user.Nationality || this.user.Nationality == "Brazil") &&
            this.user.SocialNumber.length == 14
          ) {
            this.user.JuridicalType = this.JuridicalTypeEnum.LegalPerson;
            this.step = ComplienceStepsPJEnum.JURIDICALINFO;
            this.accTypeDisabled = true;
          } else if (
            (!this.user.Nationality || this.user.Nationality == "Brazil") &&
            this.user.SocialNumber.length == 11
          ) {
            this.step = ComplienceStepsPFEnum.REGISTEERINFO;
            this.accTypeDisabled = true;
          }

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getFilledInfo() {
      this.apiService
        .getRequest("investor/filled-info")
        .then((result) => {
          this.user = JSON.parse(result);
        })
        .catch(() => {
          this.loading = false;
          this.$toast.error(this.$t("an_error_occurred"));
        });
    },
    async complete_profile() {
      this.loading = true;

      await this.apiService
        .putRequest("investor/profile/complete", {
          User: this.user,
        })
        .then((result) => {
          var user = JSON.parse(localStorage.getItem("user"));
          user.ProfileDone = this.user.ProfileDone;
          localStorage.setItem("user", JSON.stringify(user));
          this.updateBankIds();
          user.documentNumber = result.message;
          this.$store.commit("SET_LOGGED_USER", JSON.stringify(user));
          Vue.$globalEvent.$emit("profileSaved", {});
          if (this.step == ComplienceStepsPFEnum.FINALDECLARATIONS) {
            this.$router.go(-1);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    async finishProfile() {
      this.loading = true;

      var user_info = JSON.parse(localStorage.getItem("user"));
      user_info.ProfileDone = this.user.ProfileDone;
      localStorage.setItem("user", JSON.stringify(user_info));
      await this.apiService
        .putRequest("investor/profile/complete", {
          User: this.user,
        })
        .then((result) => {
          var user = JSON.parse(localStorage.getItem("user"));
          user.documentNumber = result.message;
          this.$store.commit("SET_LOGGED_USER", JSON.stringify(user));
          Vue.$globalEvent.$emit("profileSaved", {});
          if (
            this.step == ComplienceStepsPFEnum.FINALDECLARATIONS ||
            (this.step > ComplienceStepsPJEnum.EQUITYHOLDINGRESIDENT &&
              this.user.JuridicalType == this.JuridicalTypeEnum.LegalPerson)
          ) {
            this.$router.push("/profile/verify");
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    async next_step(userInfo) {
      await this.complete_profile();
      this.step++;
    },
    async previous_step() {
      this.step--;
    },

    async updateBankIds() {
      if (
        (((this.user.BankAccounts.length > 0 &&
          this.user.BankAccounts[0].Id == undefined) ||
          (this.user.InterBankAccounts.length > 0 &&
            this.user.InterBankAccounts[0].Id == undefined)) &&
          this.step == ComplienceStepsPFEnum.BANKINFO &&
          this.user.JuridicalType == this.JuridicalTypeEnum.NaturalPerson) ||
        (this.step == ComplienceStepsPJEnum.BANKINFO &&
          this.user.JuridicalType == this.JuridicalTypeEnum.LegalPerson)
      ) {
        this.apiService.getRequest("user/profile").then((resp) => {
          this.user = JSON.parse(resp);
        });
      }
    },
  },
};
</script>

<style lang="sass">
#register
  .v-list-item__subtitle
    -webkic-line-clamp: initial
    -webkit-box-orient: initial
</style>
<style scoped>
.SecondCadasterCard {
  background: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
</style>
