<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row>
        <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
          <b>{{ $t("personal_docs") }}</b>
        </p>
      </v-row>
      <v-row :class="isMobile() ? 'mx-0' : 'mx-10'" style="text-align: left">
        <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("document_type") }}
          </p>
          <v-autocomplete
            v-model="User.DocumentType"
            :items="docType"
            :item-text="translateDocType"
            item-value="value"
            :no-data-text="$t('not_found')"
            :rules="[enum_required]"
            @change="docTypeSelector"
            dense
            outlined
            class="field"
            data-test="complete_profile:document_type"
            color="primary"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("expedition_date") }}
          </p>
          <v-menu
            ref="menu"
            v-model="menuDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            data-test="complete_profile:expedition_date_menu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                color="primary"
                dense
                class="field"
                outlined
                style="width: 100%"
                v-model="dateText"
                v-mask="['##/##/####']"
                prepend-inner-icon="mdi-calendar"
                @blur="date = parseDate(dateText)"
                :rules="[required]"
                v-bind="attrs"
                data-test="complete_profile:expedition_date_picker"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              :rules="[required]"
              v-model="date"
              :max="new Date().toISOString().substr(0, 10)"
              min="1850-01-01"
              @change="save"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("document_number") }}
          </p>

          <v-text-field
            color="primary"
            outlined
            dense
            :disabled="disabled"
            v-mask="[currentMaskDocType]"
            :rules="[
              isForeigner == false
                ? (v) => this.handleCharacteres(v)
                : (v) => (!!v && v.length > 5) || required(),
            ]"
            v-model="User.DocumentNumber"
            class="field"
            data-test="Alpha:PersonalDocs:TxtDocumentNumber"
          />
        </v-col>
      </v-row>

      <v-row :class="isMobile() ? 'mx-0' : 'mx-10'" style="text-align: left">
        <v-col cols="7" xl="6" lg="6" md="6" sm="6" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("issuing_body") }}
          </p>

          <v-text-field
            class="field"
            type="text"
            v-on:keypress="isLetter($event)"
            color="primary"
            :rules="[required]"
            outlined
            dense
            v-model="User.Issuer"
            data-test="complete_profile:issuing_body"
          />
        </v-col>
        <v-col cols="5" xl="6" lg="6" md="6" sm="6" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("state") }}
          </p>

          <v-autocomplete
            class="field"
            color="primary"
            outlined
            v-if="isForeigner == false"
            :no-data-text="$t('not_found')"
            :items="states"
            :rules="[required]"
            item-text="Nome"
            item-value="Sigla"
            dense
            v-model="User.IssuerState"
            data-test="complete_profile:state_autocomplete"
          />
          <v-text-field
            class="field"
            type="text"
            v-else
            color="primary"
            :rules="[required]"
            outlined
            dense
            v-model="User.IssuerState"
            data-test="complete_profile:state_text"
          />
        </v-col>
      </v-row>

      <v-row :class="isMobile() ? 'mx-0' : 'mx-10'" style="text-align: left">
        <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("mother_name") }}
          </p>
          <v-text-field
            class="field"
            v-on:keypress="isLetter($event)"
            color="primary"
            outlined
            type="text"
            dense
            :rules="[required]"
            v-model="User.MotherName"
            data-test="complete_profile:mother"
          />
        </v-col>

        <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("father_name") }}
          </p>
          <v-text-field
            type="text"
            class="field"
            v-on:keypress="isLetter($event)"
            color="primary"
            outlined
            dense
            :rules="[required]"
            v-model="User.FatherName"
            data-test="complete_profile:father"
          />
        </v-col>
      </v-row>

      <span>
        {{ $t("legal_representative_question") }}
      </span>
      <v-row>
        <v-col class="d-inline-flex justify-center">
          <v-radio-group
            row
            center
            v-model="User.HasLegalRepresentative"
            data-test="complete_profile:legal_representative"
          >
            <v-radio
              color="primary"
              :value="true"
              data-test="Alpha:PersonalDocs:RadioHasLegalRepresentative"
            >
              <template v-slot:label>
                <span style="color: var(--dark)">{{ $t("yes") }}</span>
              </template></v-radio
            >
            <v-radio
              color="primary"
              :value="false"
              data-test="Alpha:PersonalDocs:RadioHasNoLegalRepresentative"
            >
              <template v-slot:label>
                <span style="color: var(--dark)">{{ $t("no") }}</span>
              </template></v-radio
            >
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn
          outlined
          class="btn_back mr-1"
          color="primary"
          :loading="loading"
          @click="$emit('back')"
          data-test="complete_profile:btn_back_personal_docs"
        >
          <span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
        >
        <v-btn
          type="submit"
          color="primary"
          :loading="loading"
          class="btn_next ml-1"
          data-test="complete_profile:btn_next_personal_docs"
        >
          <span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
        >
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.btn_back {
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  width: 125px !important;
  height: 42px !important;
}

.field {
  box-sizing: border-box;
  border-radius: 10px;
  color: var(--primary);
}
</style>

<script>
import ApiService from "@/services/ApiService";
import StatesName from "./states.json";
import moment from "moment";
const DocTypeEnum = {
  RG: 0,
  CNH: 1,
  PASSAPORTE: 2,
  NONE: -1,
};
export default {
  name: "ProfilePersonalDocs",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    valid: true,
    lazy: false,
    date: null,
    dateText: null,
    menuDate: false,
    alert: null,
    error: null,
    numberOfCharacters: 12,
    currentMaskDocType: null,
    states: StatesName,
    isForeigner: null,
    disabled: true,

    docType: [
      {
        text: "select",
        value: -1,
      },
      {
        text: "rg",
        value: DocTypeEnum.RG,
      },
      {
        text: "cnh",
        value: DocTypeEnum.CNH,
      },
    ],
  }),

  async created() {
    if (
      this.User.ExpeditionDate &&
      this.User.ExpeditionDate !== "0001-01-01T00:00:00"
    ) {
      this.dateText = moment(this.User.ExpeditionDate).format("DD/MM/YYYY");
      this.date = this.User.ExpeditionDate;
    }
    if (this.User.DocumentNumber == null) {
      this.User.DocumentType = DocTypeEnum.NONE;
    }
    if (
      this.User.Nationality == "Brazil" ||
      this.User.Nationality == "Brasil" ||
      this.User.Nationality == null
    ) {
      this.docType.push({
        text: "passport",
        value: 2,
      });
      this.isForeigner = false;

      if (this.User.DocumentType == DocTypeEnum.NONE) {
        this.disabled = true;
        this.numberOfCharacters = 0;
        this.currentMaskDocType = "";
      } else if (this.User.DocumentType == DocTypeEnum.RG) {
        this.disabled = false;
        this.numberOfCharacters = 20;
        this.currentMaskDocType = "####################";
      } else if (this.User.DocumentType == DocTypeEnum.CNH) {
        this.disabled = false;
        this.numberOfCharacters = 11;
        this.currentMaskDocType = "###########";
      } else if (this.User.DocumentType == DocTypeEnum.PASSAPORTE) {
        this.disabled = false;
        this.numberOfCharacters = 8;
        this.currentMaskDocType = "AA######";
      }
    } else if (
      this.User.Nationality != "Brazil" ||
      this.User.Nationality != "Brasil"
    ) {
      this.isForeigner = true;

      if (this.User.DocumentType == DocTypeEnum.NONE) {
        this.disabled = true;
        this.numberOfCharacters = 0;
        this.currentMaskDocType = "";
      } else if (
        this.User.DocumentType == DocTypeEnum.RG ||
        this.User.DocumentType == DocTypeEnum.CNH
      ) {
        this.disabled = false;
        this.numberOfCharacters = 20;
        this.currentMaskDocType = "NNNNNNNNNNNNNNNNNNNN";
      }
    }
  },
  props: {
    User: Object,
  },
  computed: {
    enum_required() {
      return (value) => value >= 0 || this.$t("required");
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
    min_chars() {
      return (value) =>
        value.length >= this.numberOfCharacters ||
        this.$t("doc_min_chars", { value: this.numberOfCharacters });
    },
  },
  methods: {
    parseDocumentNumber() {
      return this.User.DocumentNumber.replace(/[^0-9]/g, "")
        .replace(/[^0-9]/g, "")
        .replace("-", "");
    },

    // Se for RG, não valida quantidade de characters
    handleCharacteres(value) {
      if (this.User.DocumentType === DocTypeEnum.RG) {
        return !!value || this.required();
      } else {
        return (
          (!!value && value.length == this.numberOfCharacters) ||
          this.min_chars(this.numberOfCharacters)
        );
      }
    },

    existenceVerify() {
      if (parseInt(states.ID) < 0 && parseInt(states.ID) > 27) {
      }
    },

    formatInputDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    save(date) {
      this.dateText = this.formatInputDate(this.date);
      this.$refs.menu.save(date);
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    docTypeSelector() {
      if (
        this.User.Nationality == "Brazil" ||
        this.User.Nationality == "Brasil" ||
        this.User.Nationality == null
      ) {
        if (this.User.DocumentType == DocTypeEnum.NONE) {
          this.disabled = true;
          this.numberOfCharacters = 0;
          this.currentMaskDocType = "";
        } else if (this.User.DocumentType == DocTypeEnum.RG) {
          this.numberOfCharacters = 20;
          this.currentMaskDocType = "####################";
          this.disabled = false;
        } else if (this.User.DocumentType == DocTypeEnum.CNH) {
          this.disabled = false;
          this.numberOfCharacters = 11;
          this.currentMaskDocType = "###########";
        } else if (this.User.DocumentType == DocTypeEnum.PASSAPORTE) {
          this.disabled = false;
          this.numberOfCharacters = 8;
          this.currentMaskDocType = "AA######";
        }
      } else if (
        this.User.Nationality != "Brazil" ||
        this.User.Nationality != "Brasil"
      ) {
        if (this.User.DocumentType == DocTypeEnum.NONE) {
          this.disabled = true;
          this.numberOfCharacters = 0;
          this.currentMaskDocType = "";
        } else if (
          this.User.DocumentType == DocTypeEnum.RG ||
          this.User.DocumentType == DocTypeEnum.CNH
        ) {
          this.disabled = false;
          this.numberOfCharacters = 20;
          this.currentMaskDocType = "NNNNNNNNNNNNNNNNNNNN";
        } else if (this.User.DocumentType == DocTypeEnum.PASSAPORTE) {
          this.disabled = false;
          this.numberOfCharacters = 8;
          this.currentMaskDocType = "AA######";
        }
      }
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (
        /^[A-Za-z]+$/.test(char) ||
        char == "ç" ||
        char == "Ç" ||
        char == "á" ||
        char == "Á" ||
        char == "é" ||
        char == "É" ||
        char == "í" ||
        char == "Í" ||
        char == "ó" ||
        char == "Ó" ||
        char == "ú" ||
        char == "Ú" ||
        char == "ó" ||
        char == "Ó" ||
        char == "â" ||
        char == "Â" ||
        char == "ê" ||
        char == "Ê" ||
        char == "î" ||
        char == "Î" ||
        char == "ô" ||
        char == "Ô" ||
        char == "û" ||
        char == "Û" ||
        char == "ã" ||
        char == "Ã" ||
        char == "õ" ||
        char == "Õ" ||
        char == " "
      )
        return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    translateDocType(item) {
      return this.$t(item.text);
    },
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        this.User.ExpeditionDate = this.date;
        this.$emit("next");
      }
    },
  },
};
</script>
