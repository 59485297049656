import { render, staticRenderFns } from "./Statements.vue?vue&type=template&id=3ccaf4f4&scoped=true&"
import script from "./Statements.vue?vue&type=script&lang=js&"
export * from "./Statements.vue?vue&type=script&lang=js&"
import style0 from "./Statements.vue?vue&type=style&index=0&id=3ccaf4f4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ccaf4f4",
  null
  
)

export default component.exports