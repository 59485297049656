<template>
  <div>
    <v-row>
      <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
        <b>{{ $t("equity_holding") }}</b>
      </p>
    </v-row>
    <v-btn
      color="primary"
      rounded
      @click="open_dialog(null)"
      absolute
      fab
      top
      right
    >
      <v-icon large>mdi-plus</v-icon>
    </v-btn>
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-center">{{ $t("name") }}</th>
          <th class="text-center">{{ $t("cpf") }}</th>
          <th class="text-center">{{ $t("nationality") }}</th>
          <th class="text-center">{{ $t("capital") }}</th>
          <th class="text-center">{{ $t("is_politically_exposed") }}</th>
          <th class="text-center">{{ $t("options") }}</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(eh, index) in User.EquityHoldings" :key="index">
          <td class="text-center">{{ eh.Name }}</td>
          <td class="text-center">{{ eh.RegistryNumber }}</td>
          <td class="text-center">{{ eh.Nationality }}</td>
          <td class="text-center">{{ eh.Capital }}%</td>
          <td class="text-center">
            {{ eh.PoliticallyExposed ? $t("yes") : $t("no") }}
          </td>
          <td class="text-center">
            <v-btn
              class="px-2 ml-1 secondary"
              @click="open_dialog(eh)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="px-2 ml-1"
              @click="open_delete_dialog(eh)"
              color="red"
              min-width="0"
              small
            >
              <v-icon small>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-row justify="center" class="mt-6">
      <v-btn
        outlined
        class="mr-1"
        color="primary"
        :loading="loading"
        @click="$emit('back')"
        ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
      >
      <v-btn
        @click="$emit('next')"
        color="primary"
        :loading="loading"
        class="ml-1"
        ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
      >
    </v-row>
    <v-dialog v-model="dialog" v-if="dialog">
      <AddEquityHolding
        :Obj="equityHolding"
        :UserId="User.Id"
        @update="update"
        @close="dialog = false"
      ></AddEquityHolding>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="equityHolding"
        :name="$t('this_equity_holding')"
        @close="delete_dialog = false"
        @delete="delete_equity_holding"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import AddEquityHolding from "../form/AddEquityHoldingModal";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
export default {
  name: "EquityHoldin",
  components: { AddEquityHolding, DeleteConfirmationModal },
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    dialog: false,
    delete_dialog: false,
    equityHolding: null,
  }),
  computed: {},
  async created() {},
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    open_delete_dialog(obj) {
      this.equityHolding = obj;
      this.delete_dialog = true;
    },
    delete_equity_holding(obj) {
      var index = this.User.EquityHoldings.indexOf(obj);
      this.User.EquityHoldings.splice(index, 1);
      this.delete_dialog = false;
    },
    open_dialog(obj) {
      this.equityHolding = obj;
      this.dialog = true;
    },
    update(obj) {
      if (this.equityHolding == null) {
        this.User.EquityHoldings.push(obj);
      } else {
        var index = this.User.EquityHoldings.indexOf(this.equityHolding);
        this.User.EquityHoldings[index] = obj;
      }
      this.dialog = false;
    },
  },
};
</script>
