<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row>
        <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
          <b>{{ $t("non_resident_investor") }}</b>
        </p>
      </v-row>
      <div class="my-2">
        <span>
          {{ $t("rde") }}
        </span>
        <v-row class="my-4" :class="isMobile() ? 'mx-2' : 'mx-12'">
          <v-text-field
            color="primary"
            outlined
            dense
            :rules="[required]"
            v-model="User.NonResidentInvestor.RDE"
          />
        </v-row>
      </div>
      <div class="my-2">
        <span>
          {{ $t("country_representative") }}
        </span>
        <v-row class="my-4" :class="isMobile() ? 'mx-2' : 'mx-12'">
          <v-text-field
            color="primary"
            outlined
            dense
            :rules="[required]"
            v-model="User.NonResidentInvestor.CountryLegalRepresentative"
          />
        </v-row>
      </div>
      <div class="my-2">
        <span>
          {{ $t("cpf") }}
        </span>
        <v-row class="my-4" :class="isMobile() ? 'mx-2' : 'mx-12'">
          <v-text-field
            color="primary"
            outlined
            dense
            :rules="[required]"
            v-model="
              User.NonResidentInvestor.CountryLegalRepresentativeSocialNumber
            "
          />
        </v-row>
      </div>

      <div class="my-2">
        <span>
          {{ $t("tax_representative") }}
        </span>
        <v-row class="my-4" :class="isMobile() ? 'mx-2' : 'mx-12'">
          <v-text-field
            color="primary"
            outlined
            dense
            :rules="[required]"
            v-model="User.NonResidentInvestor.TaxRepresentative"
          />
        </v-row>
      </div>
      <div class="my-2">
        <span>
          {{ $t("cpf") }}
        </span>
        <v-row class="my-4" :class="isMobile() ? 'mx-2' : 'mx-12'">
          <v-text-field
            color="primary"
            outlined
            dense
            :rules="[required]"
            v-model="User.NonResidentInvestor.TaxRepresentativeSocialNumber"
          />
        </v-row>
      </div>

      <v-row justify="center">
        <v-btn
          outlined
          class="mr-1"
          color="primary"
          :loading="loading"
          @click="$emit('back')"
          ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
        >
        <v-btn type="submit" color="primary" :loading="loading" class="ml-1"
          ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
        >
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "NonResidentInvestorInfo",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    valid: true,
    lazy: false,
    alert: null,
    error: null,
  }),
  computed: {},
  async created() {},
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        this.$emit("next");
      }
    },
  },
};
</script>
