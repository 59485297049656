<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row>
        <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
          <strong>{{ $t("register_info") }}</strong>
        </p>
      </v-row>

      <span>
        {{ $t("marital_status") }}
      </span>
      <div class="mt-3">
        <v-row
          class="d-inline-flex justify-center"
          :class="isMobile() ? 'mx-2' : 'mx-12'"
        >
          <v-autocomplete
            color="primary"
            dense
            outlined
            v-model="User.MaritalStatus"
            :rules="[autocomplete_required]"
            :items="maritalStatus"
            :item-text="translateMaritalStatus"
            item-value="value"
            class="field"
            data-test="complete_profile:marital_status"
          ></v-autocomplete>
        </v-row>
      </div>

      <span>
        {{ $t("sex") }}
      </span>
      <div>
        <v-row
          :class="isMobile() ? 'mx-2' : 'mx-12'"
          class="d-inline-flex justify-center my-4"
        >
          <v-autocomplete
            dense
            color="primary"
            outlined
            v-model="User.Sex"
            :rules="[autocomplete_required]"
            :items="sexType"
            :item-text="translateSexType"
            item-value="value"
            class="field"
            data-test="complete_profile:sex"
          ></v-autocomplete>
        </v-row>
      </div>

      <v-row justify="center">
        <v-btn
          outlined
          class="btn_back mr-1"
          color="primary"
          :loading="loading"
          @click="$emit('back')"
          data-test="complete_profile:btn_back_register_info"
          disabled="disabled"
          ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
        >
        <v-btn
          type="submit"
          color="primary"
          :loading="loading"
          class="btn_next ml-1"
          data-test="complete_profile:btn_next_register_info"
          ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
        >
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.btn_back {
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  width: 125px !important;
  height: 42px !important;
}
.field {
  box-sizing: border-box;
  border-radius: 10px;
}
</style>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "ProfileRegisterInfo",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    menuDate: false,
    loading: false,
    valid: true,
    lazy: false,
    date: null,
    sexType: [
      {
        text: "select",
        value: -1,
      },
      {
        text: "sex_female",
        value: 0,
      },
      {
        text: "sex_male",
        value: 1,
      },
      {
        text: "other",
        value: 2,
      },
    ],
    maritalStatus: [
      {
        text: "select",
        value: -1,
      },
      { text: "single", value: 0 },

      { text: "married", value: 1 },

      { text: "widower", value: 2 },

      { text: "divorced", value: 3 },
    ],
  }),
  async created() {},
  props: {
    User: Object,
  },
  computed: {
    autocomplete_required() {
      return (value) => value > -1 || this.$t("required");
    },
  },
  methods: {
    translateSexType(item) {
      return this.$t(item.text);
    },
    translateMaritalStatus(item) {
      return this.$t(item.text);
    },
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        this.$emit("next");
      }
    },
  },
};
</script>
