<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row>
        <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
          <b>{{ $t("contact_profile") }}</b>
        </p>
      </v-row>
      <div class="my-2 mt-4" hide-details>
        <v-row :class="isMobile() ? 'mx-4' : 'mx-14'">
          <span class="country-label">
            {{ $t("country") }}
          </span>
        </v-row>
        <v-row :class="isMobile() ? 'mx-2' : 'mx-12'">
          <v-select
            class="field"
            dense
            outlined
            :items="countries"
            item-text="name"
            return-object
            @change="country_code_changed"
            :rules="[required]"
            v-model="countrySelected"
            :loading="!countries || countries.length === 0"
            data-test="complete_profile:country"
            color="primary"
          >
            <template slot="selection" slot-scope="data">
              <img :src="data.item.flag" style="width: 20px; height: auto" />
              <!-- HTML that describe how select should render selected items -->
              <span class="ml-2" style="text-transform: none">{{
                data.item.name
              }}</span>
            </template>
            <template slot="item" slot-scope="data">
              <!-- HTML that describe how select should render items when the select is open -->
              <img :src="data.item.flag" style="width: 20px; height: auto" />
              <span class="ml-2" style="text-transform: none">{{
                data.item.name
              }}</span>
            </template>
          </v-select>
        </v-row>
      </div>
      <div>
        <v-row :class="isMobile() ? 'mx-0' : 'mx-10'">
          <v-col style="text-align: left" class="py-0 postCode-box">
            <h4 class="mb-2 mr-3 postCode-title">
              {{ $t("post_code") }} <v-spacer></v-spacer>
            </h4>
            <p class="manually colorized">
              {{ $t("inform_your_post_code") }}
              <a @click="disabled_field = false" class="postCode-link">{{
                $t("enter_manually")
              }}</a>
            </p>
          </v-col>
        </v-row>
        <v-row
          class="requiredMessage-margin"
          :class="isMobile() ? 'mx-0' : 'mx-10'"
          style="text-align: left"
        >
          <v-col xl="3" lg="3" md="3" sm="3" display:block class="py-0">
            <v-text-field
              placeholder="00000-000"
              color="primary"
              outlined
              dense
              @keyup="post_code_changed"
              :rules="[required]"
              v-model="User.PostCode"
              class="field py-0"
              data-test="complete_profile:post_code"
            />
          </v-col>
          <v-col xl="4" lg="4" md="4" sm="4" class="py-0 px-0">
            <a
              class="manually"
              style="display: flex; justify-content: left; align-items: left"
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target="_blank"
              >{{ $t("dont_know_post_code") }}</a
            >
          </v-col>
        </v-row>
      </div>
      <div style="text-align: left">
        <v-row :class="isMobile() ? 'mx-0' : 'mx-10'">
          <v-col
            cols="8"
            xl="5"
            lg="5"
            md="5"
            sm="5"
            display:block
            class="py-0"
          >
            <p class="ml-2 mb-2">
              {{ $t("city") }}
            </p>
            <v-text-field
              class="field"
              color="primary"
              outlined
              dense
              :loading="fields_loading"
              :rules="[required]"
              v-model="User.City"
              :disabled="disabled_field"
              data-test="complete_profile:city"
            />
          </v-col>

          <v-col cols="4" xl="2" lg="2" md="2" sm="2" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("state") }}
            </p>
            <v-text-field
              class="field"
              color="primary"
              outlined
              dense
              :loading="fields_loading"
              :rules="[required]"
              v-model="User.State"
              :disabled="disabled_field"
              data-test="complete_profile:state"
            />
          </v-col>

          <v-col
            cols="12"
            xl="5"
            lg="5"
            md="5"
            sm="5"
            class="py-0 requiredMessage-margin"
          >
            <p class="ml-2 mb-2">
              {{ $t("neighborhood") }}
            </p>
            <v-text-field
              class="field pa-0 ma-0"
              color="primary"
              outlined
              dense
              :loading="fields_loading"
              :rules="[required]"
              v-model="User.Neighborhood"
              :disabled="disabled_field"
              data-test="complete_profile:neighborhood"
            />
          </v-col>
        </v-row>
      </div>
      <div style="text-align: left">
        <v-row :class="isMobile() ? 'mx-0' : 'mx-10'">
          <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("address") }}
            </p>
            <v-text-field
              class="field"
              color="primary"
              outlined
              dense
              :loading="fields_loading"
              :disabled="disabled_field"
              :rules="[required]"
              v-model="User.Address"
              data-test="complete_profile:address"
            />
          </v-col>

          <v-col cols="6" xl="3" lg="3" md="3" sm="3" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("number") }}
            </p>
            <v-text-field
              color="primary"
              class="field mt-2"
              outlined
              type="number"
              dense
              :rules="[number_required]"
              :disabled="noNumber || disabled_field"
              v-model="User.AddressNumber"
              data-test="complete_profile:number"
            />
          </v-col>
          <v-col cols="6" xl="3" lg="3" md="3" sm="3" class="px-0">
            <v-checkbox
              color="primary"
              class="text-field"
              :label="$t('no_number')"
              v-model="noNumber"
              @change="noNumber_change"
              style="color: var(--dark)"
              :disabled="disabled_field"
              data-test="complete_profile:no_number"
            ></v-checkbox
          ></v-col>
        </v-row>
      </div>
      <div style="text-align: left" class="requiredMessage-margin">
        <v-row style="display: block" :class="isMobile() ? 'mx-0' : 'mx-10'">
          <v-col cols="12" md="12" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("complement") }}
            </p>
            <v-text-field
              color="primary"
              class="field mt-2"
              outlined
              dense
              v-model="User.AddressComplement"
              :disabled="disabled_field"
              data-test="complete_profile:complement"
            />
          </v-col>

          <v-col cols="12" md="12" class="py-0">
            <p class="ml-2 mb-2">
              {{ $t("home_phone") }}
            </p>
            <v-text-field
              color="primary"
              class="field"
              outlined
              v-mask="[PhoneMask]"
              dense
              :rules="[required]"
              v-model="User.HomePhone"
              data-test="complete_profile:home_phone"
            />
          </v-col>
        </v-row>
      </div>

      <v-row justify="center">
        <v-btn
          outlined
          class="btn_back mr-1"
          color="primary"
          :loading="loading"
          @click="$emit('back')"
          data-test="complete_profile:btn_back_contacts"
          ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
        >
        <v-btn
          type="submit"
          color="primary"
          :loading="loading"
          class="btn_next ml-1 mr-0"
          data-test="complete_profile:btn_next_contacts"
          ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
        >
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.btn_back {
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  width: 125px !important;
  height: 42px !important;
}
.field {
  box-sizing: border-box;
  border-radius: 10px;
  color: var(--primary);
}
.manually {
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.15px;
}
.colorized {
  color: #9c9c9c;
}
// .text-field :disabled {
//   background: rgb(240, 240, 240);
//   padding-top: 17px;
//   padding-bottom: 17px;
//   padding-left: 19px;

//   margin: 0px;
// }

.country-label {
  display: block;
  margin-bottom: 8px;
}

.postCode-box {
  display: flex;
  flex-direction: row;
}

.postCode-title {
  margin-left: 8px;
}

.postCode-link {
  text-decoration: underline;
}

.requiredMessage-margin {
  margin-bottom: 8px;
}

@media only screen and (max-width: 769px) {
  .postCode-box {
    flex-direction: column;
  }

  .postCode-title {
    margin-left: 0px;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
import axios from "axios";
import countriesJson from "@/utils/countries.json";

export default {
  name: "ProfileContacts",
  components: {},
  data: () => ({
    noNumber: false,
    apiService: new ApiService(),
    loading: false,
    valid: true,
    lazy: false,
    alert: null,
    countrySelected: null,
    countries: countriesJson,

    fields_loading: false,
    error: null,
    disabled_field: true,
    PhoneMask: null,
  }),
  async created() {
    if (this.User.Country == null) {
      this.User.Country = this.User.Nationality;
    }
    this.countrySelected = this.countries.filter(
      (x) => x.name == this.User.Country
    )[0];

    this.noNumber = !this.User.AddressNumber;
    this.getPhoneMask();
    this.post_code_changed();
    // this.get_country_codes();
  },
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    number_required() {
      return (value) => this.noNumber || !!value || this.$t("required");
    },
  },
  methods: {
    noNumber_change() {
      if (this.noNumber) {
        this.User.AddressNumber = "";
      }
    },
    getPhoneMask() {
      if (this.User.Nationality == "Brazil") {
        this.PhoneMask = "(##) #####-####";
      } else {
        this.PhoneMask = "(###) ###-####";
      }
    },
    get_country_codes() {
      this.apiService
        .getRequest(`health/country-codes`)
        .then((resp) => {
          this.countries = JSON.parse(resp.resp);
          this.countrySelected = this.countries.filter(
            (x) => x.name == "Brazil"
          )[0];
          this.User.Country = "Brazil";
        })
        .catch((err) => {});
    },
    country_code_changed() {
      if (this.countrySelected.name) {
        this.User.Country = this.countrySelected.name;
      }

      if (this.User.country !== "Brazil") {
        this.disabled_field = false;
      } else {
        this.disabled_field = true;
      }
    },
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    post_code_changed() {
      if (this.User.Country != "Brazil") {
        this.disabled_field = false;
        return;
      }
      var cep = this.User.PostCode.replace("-", "");
      if (cep.length >= 8) {
        this.fields_loading = true;
        axios
          .get(`https://viacep.com.br/ws/${cep}/json/`)
          .then((resp) => {
            var json = resp.data;
            this.User.Neighborhood = json.bairro;
            this.User.PostCode = json.cep;
            this.User.State = json.uf;
            this.User.City = json.localidade;
            this.User.Address = json.logradouro;
            this.fields_loading = false;
            this.disabled_field = false;
            this.noNumber = false;
          })
          .catch((err) => {
            this.fields_loading = false;
          });
      }
    },
    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        this.$emit("next");
      }
    },
  },
};
</script>
