<template>
  <div>
    <v-row>
      <p class="mx-auto" style="font-size: 1.5rem; color: var(--dark)">
        <b>{{ $t("stock_position") }}</b>
      </p>
    </v-row>
    <v-btn
      color="primary"
      rounded
      @click="open_dialog(null)"
      absolute
      fab
      top
      right
    >
      <v-icon large>mdi-plus</v-icon>
    </v-btn>

    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            color="primary"
            v-model="User.NetWorth"
            type="number"
            prefix="R$"
            @change="User.NetWorth = parseFloat(User.NetWorth)"
            :rules="[required]"
            class="purple-input"
            :label="$t('net_worth')"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            color="primary"
            v-model="User.AverageMonthlyRevenue"
            type="number"
            prefix="R$"
            @change="
              User.AverageMonthlyRevenue = parseFloat(
                User.AverageMonthlyRevenue
              )
            "
            :rules="[required]"
            class="purple-input"
            :label="$t('average_monthly_revenue')"
          />
        </v-col>
      </v-row>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-center">{{ $t("name") }}</th>
            <th class="text-center">{{ $t("cpf") }}</th>
            <th class="text-center">{{ $t("nationality") }}</th>
            <th class="text-center">{{ $t("capital") }}</th>
            <th class="text-center">{{ $t("options") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(sp, index) in User.StockPositions" :key="index">
            <td class="text-center">{{ sp.Name }}</td>
            <td class="text-center">{{ sp.RegistryNumber }}</td>
            <td class="text-center">{{ sp.Nationality }}</td>
            <td class="text-center">{{ sp.Capital }}%</td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1 secondary"
                @click="open_dialog(sp)"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="px-2 ml-1"
                @click="open_delete_dialog(sp)"
                color="red"
                min-width="0"
                small
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-row justify="center" class="mt-6">
        <v-btn
          outlined
          class="mr-1"
          color="primary"
          :loading="loading"
          @click="$emit('back')"
          ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
        >
        <v-btn type="submit" color="primary" :loading="loading" class="ml-1"
          ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
        >
      </v-row>
    </v-form>
    <v-dialog v-model="dialog" v-if="dialog">
      <AddStockPosition
        :Obj="stockPosition"
        :UserId="User.Id"
        @update="update"
        @close="dialog = false"
      ></AddStockPosition>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="stockPosition"
        :name="$t('this_stock_position')"
        @close="delete_dialog = false"
        @delete="delete_stock_position"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import AddStockPosition from "../form/AddStockPositionModal";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
export default {
  name: "StockPosition",
  components: { AddStockPosition, DeleteConfirmationModal },
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    dialog: false,
    valid: true,
    lazy: false,
    delete_dialog: false,
    stockPosition: null,
  }),
  async created() {},
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        this.$emit("next");
      }
    },
    open_delete_dialog(obj) {
      this.stockPosition = obj;
      this.delete_dialog = true;
    },
    delete_stock_position(obj) {
      var index = this.User.StockPositions.indexOf(obj);
      this.User.StockPositions.splice(index, 1);
      this.delete_dialog = false;
    },
    open_dialog(obj) {
      this.stockPosition = obj;
      this.dialog = true;
    },
    update(obj) {
      if (this.stockPosition == null) {
        this.User.StockPositions.push(obj);
      } else {
        var index = this.User.StockPositions.indexOf(this.stockPosition);
        this.User.StockPositions[index] = obj;
      }
      this.dialog = false;
    },
  },
};
</script>
