<template>
  <div>
    <v-row justify="center">
      <v-checkbox
        color="primary"
        :rules="[required]"
        class="my-0 py-0"
        @click="open_declarations"
        readonly
        v-model="agreed_declarations"
        data-test="complete_profile:agreed_declarations"
      >
        <template v-slot:label>
          <a
            style="color: var(--dark)"
            @click="declarations = true"
            v-html="$t('agree_final_declarations')"
          ></a> </template
      ></v-checkbox>
    </v-row>
    <v-row justify="center" class="mt-6">
      <v-btn
        outlined
        class="btn_back mr-1 final-declarations-button-back"
        color="primary"
        @click="$emit('back')"
        data-test="complete_profile:btn_back_final_declarations"
        ><span style="font-size: 1.3rem">{{ $t("go_back") }}</span></v-btn
      >
      <v-btn
        @click="next"
        color="primary"
        class="ml-1"
        :disabled="!agreed_declarations"
        data-test="complete_profile:btn_conclude_final_declarations"
        ><span style="font-size: 1.3rem">{{ $t("conclude") }}</span></v-btn
      >
    </v-row>
    <v-dialog persistent v-model="declarations">
      <v-card class="final-declarations-modal-bg" color="white">
        <div class="pa-4">
          <p style="color: var(--dark)">
            <b>{{ $t("final_declarations_header") }}</b>
          </p>
          <p style="color: var(--dark)">1: {{ $t("final_declarations_1") }}</p>
          <p style="color: var(--dark)">2: {{ $t("final_declarations_2") }}</p>
          <p style="color: var(--dark)">3: {{ $t("final_declarations_3") }}</p>
          <v-row
            justify="center"
            class="final-declarations-modal-button-agree-terms"
          >
            <v-btn
              @click="agree_declarations"
              class="dxa-btn-primary"
              data-test="complete_profile:btn_agree_final_declarations"
              ><span v-html="$t('agree_final_declarations')"></span
            ></v-btn>
          </v-row>
          <v-row justify="center" class="mt-2">
            <v-btn
              @click="decline_declarations"
              class="dxa-btn-secondary-danger"
              data-test="complete_profile:btn_close_final_declarations"
              ><span>{{ $t("close") }}</span></v-btn
            >
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.btn_back {
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  width: 155px !important;
  height: 42px !important;
}

.final-declarations-modal-button-agree-terms {
  margin-bottom: 8px;
  margin-top: 8px;
}

.final-declarations-modal-bg {
  max-height: 60vh;
  overflow-y: auto;
  padding-bottom: 10px;
}

@media only screen and (max-width: 769px) {
  .final-declarations-button-back {
    margin-bottom: 16px;
  }
}
</style>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "FinalDeclarations",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    declarations: false,
    agreed_declarations: false,
  }),
  async created() {},
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    declarations_text() {
      return [
        `<p style="color: var(--dark)">1: ${this.$t(
          "final_declarations_1"
        )}</p>`,
        `<p style="color: var(--dark)">2: ${this.$t(
          "final_declarations_2"
        )}</p>`,
        `<p style="color: var(--dark)">3: ${this.$t(
          "final_declarations_3"
        )}</p>`,
      ];
    },
  },
  methods: {
    open_declarations() {
      this.agreed_declarations = false;
      this.declarations = true;
    },
    agree_declarations() {
      this.agreed_declarations = true;
      this.declarations = false;
    },
    decline_declarations() {
      this.agreed_declarations = false;
      this.declarations = false;
    },
    next() {
      this.User.ProfileDone = true;
      this.$emit("next");
    },
  },
};
</script>
