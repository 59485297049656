<template>
  <div>
    <v-form
      @submit.stop.prevent="checkForm()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-row :class="isMobile() ? 'mx-0' : 'mx-10'" style="text-align: left">
        <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("company_name") }}
          </p>
          <v-text-field
            class="field"
            color="primary"
            outlined
            dense
            :rules="[required]"
            v-model="User.CompanyName"
          />
        </v-col>

        <v-col cols="12" xl="3" lg="3" md="3" sm="3" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("state_registration") }}
          </p>

          <v-text-field
            class="field"
            color="primary"
            outlined
            dense
            v-model="User.StateRegistration"
          />
        </v-col>

        <v-col cols="12" xl="5" lg="5" md="5" sm="5" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("main_economic_activity") }}
          </p>
          <v-text-field
            class="field"
            color="primary"
            outlined
            dense
            :rules="[required]"
            v-model="User.MainEconomicActivity"
          />
        </v-col>
      </v-row>

      <v-row :class="isMobile() ? 'mx-0' : 'mx-10'" style="text-align: left">
        <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("tax_address") }}
          </p>

          <v-text-field
            class="field"
            color="primary"
            outlined
            dense
            :hint="$t('tax_address_hint')"
            :rules="[required]"
            v-model="User.TaxAddress"
          />
        </v-col>

        <v-col cols="12" xl="3" lg="3" md="3" sm="3" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("cnae") }}
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"
                  >mdi-help-circle-outline</v-icon
                >
              </template>
              <span>{{ $t("extended_cnae") }}</span>
            </v-tooltip>
          </p>
          <v-text-field
            color="primary"
            class="field"
            outlined
            dense
            v-model="User.CNAE"
          />
        </v-col>
      </v-row>

      <div>
        <v-row :class="isMobile() ? 'mx-0' : 'mx-10'" style="text-align: left">
          <v-col cols="7" xl="3" lg="3" md="3" sm="3" class="py-0 pr-0">
            <p class="ml-2 mb-2">
              {{ $t("country") }}
            </p>
            <v-select
              color="primary"
              dense
              outlined
              class="field"
              :items="countries"
              item-text="name"
              return-object
              @change="country_code_changed"
              :rules="[required]"
              v-model="countrySelected"
            >
              <template slot="selection" slot-scope="data">
                <img
                  :src="data.item.flag"
                  style="width: 20px; height: auto"
                  alt=""
                />
                <!-- HTML that describe how select should render selected items --><span
                  class="ml-2"
                  style="text-transform: none"
                  >{{ data.item.name }}</span
                >
              </template>
              <template slot="item" slot-scope="data">
                <img
                  :src="data.item.flag"
                  style="width: 20px; height: auto"
                  alt=""
                />

                <!-- HTML that describe how select should render selected items --><span
                  class="ml-2"
                  style="text-transform: none"
                  >{{ data.item.name }}</span
                >
              </template>
            </v-select>
          </v-col>

          <v-col
            style="text-align: left"
            xl="9"
            lg="9"
            md="9"
            sm="9"
            class="py-0"
          >
            <h4 class="ml-2 mb-2">
              {{ $t("post_code") }}
              <span color="#ccc" class="manually colorized pa-0 ml-3">
                {{ $t("inform_your_post_code") }}
                <a @click="disabled_field = false">{{
                  $t("enter_manually")
                }}</a>
              </span>
            </h4>
            <div class="d-flex">
              <v-text-field
                style="max-width: 215px"
                class="field mr-3"
                color="primary"
                outlined
                dense
                @keyup="post_code_changed"
                :rules="[required]"
                v-model="User.PostCode"
              />
              <a
                class="manually"
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                target="_blank"
              >
                {{ $t("dont_know_post_code") }}
              </a>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-row :class="isMobile() ? 'mx-0' : 'mx-10'" style="text-align: left">
        <v-col cols="7" xl="5" lg="5" md="5" sm="5" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("city") }}
          </p>
          <v-text-field
            class="field"
            color="primary"
            outlined
            dense
            :loading="fields_loading"
            :rules="[required]"
            :disabled="disabled_field"
            v-model="User.City"
          />
        </v-col>
        <v-col cols="5" xl="2" lg="2" md="2" sm="2" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("state") }}
          </p>
          <v-text-field
            class="field"
            color="primary"
            outlined
            dense
            :disabled="disabled_field"
            :loading="fields_loading"
            :rules="[required]"
            v-model="User.State"
          />
        </v-col>
        <v-col cols="12" xl="5" lg="5" md="5" sm="5" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("neighborhood") }}
          </p>
          <v-text-field
            class="field"
            color="primary"
            outlined
            dense
            :disabled="disabled_field"
            :loading="fields_loading"
            :rules="[required]"
            v-model="User.Neighborhood"
          />
        </v-col>
      </v-row>

      <v-row :class="isMobile() ? 'mx-0' : 'mx-10'" style="text-align: left">
        <v-col cols="12" xl="5" lg="5" md="5" sm="5" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("address") }}
          </p>
          <v-text-field
            class="field"
            color="primary"
            outlined
            dense
            :disabled="disabled_field"
            :loading="fields_loading"
            :rules="[required]"
            v-model="User.Address"
          />
        </v-col>
        <v-col cols="6" xl="3" lg="3" md="3" sm="3" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("number") }}
          </p>
          <v-text-field
            color="primary"
            class="field mt-2"
            :disabled="noNumber || disabled_field"
            outlined
            dense
            v-model="User.AddressNumber"
          />
        </v-col>
        <v-col class="px-0" cols="6" xl="4" lg="4" md="4" sm="4">
          <v-checkbox
            class="text-field"
            label="Sem número"
            v-model="noNumber"
            style="color: var(--dark)"
            :disabled="disabled_field"
          ></v-checkbox></v-col
      ></v-row>

      <v-row :class="isMobile() ? 'mx-0' : 'mx-10'" style="text-align: left">
        <v-col cols="12" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("complement") }}
          </p>
          <v-text-field
            color="primary"
            class="field mt-2"
            :disabled="disabled_field"
            outlined
            dense
            v-model="User.AddressComplement"
          />
        </v-col>
      </v-row>
      <v-row :class="isMobile() ? 'mx-0' : 'mx-10'" style="text-align: left">
        <v-col cols="8" md="4" class="py-0">
          <p class="ml-2 mb-2">
            {{ $t("home_phone") }}
          </p>
          <v-text-field
            color="primary"
            class="field mt-2"
            outlined
            number
            dense
            :rules="[required]"
            v-model="User.HomePhone"
          />
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-btn
          type="submit"
          color="primary"
          :loading="loading"
          class="btn_next ml-1"
          ><span style="font-size: 1.3rem">{{ $t("next") }}</span></v-btn
        >
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
.v-application.mt-10 {
  margin-top: 4px !important;
}
.field {
  box-sizing: border-box;
  border-radius: 10px;
}
.btn_back {
  width: 125px !important;
  height: 42px !important;
}
.btn_next {
  width: 125px !important;
  height: 42px !important;
}
.manually {
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.15px;
}
.colorized {
  color: #9c9c9c;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import axios from "axios";
import countriesJson from "@/utils/countries.json";

export default {
  name: "JuridicalInfo",
  components: {},
  data: () => ({
    apiService: new ApiService(),
    noNumber: false,
    loading: false,
    valid: true,
    fields_loading: false,
    countrySelected: null,
    countries: countriesJson,
    lazy: false,
    alert: null,
    error: null,
    disabled_field: true,
  }),
  async created() {
    if (this.User.Country === null) {
      this.User.Country = "Brazil";
    }

    this.countrySelected = this.countries.filter((x) => x.name == "Brazil")[0];
    this.post_code_changed();
    //this.get_country_codes();
  },
  props: {
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    get_country_codes() {
      this.apiService
        .getRequest(`health/country-codes`)
        .then((resp) => {
          this.countries = JSON.parse(resp.resp);
          this.countrySelected = this.countries.filter(
            (x) => x.name == "Brazil"
          )[0];
          this.User.Country = "Brazil";
        })
        .catch((err) => {});
    },
    country_code_changed() {
      if (this.countrySelected.name) {
        this.User.Country = this.countrySelected.name;
      }

      if (this.User.country !== "Brazil") {
        this.disabled_field = false;
      } else {
        this.disabled_field = true;
      }
    },
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    post_code_changed() {
      if (this.User.Country != "Brazil") {
        this.disabled_field = false;
        return;
      }
      let cep = this.User.PostCode.replace("-", "");
      if (cep.length >= 8) {
        this.fields_loading = true;
        axios
          .get(`https://viacep.com.br/ws/${cep}/json/`)
          .then((resp) => {
            let json = resp.data;
            this.User.Neighborhood = json.bairro;
            this.User.PostCode = json.cep;
            this.User.State = json.uf;
            this.User.City = json.localidade;
            this.User.Address = json.logradouro;
            this.fields_loading = false;
            this.disabled_field = false;
          })
          .catch((err) => {
            this.fields_loading = false;
          });
      }
    },
    checkForm: function () {
      var result = this.$refs.form.validate();
      if (result) {
        this.$emit("next");
      }
    },
  },
};
</script>
