import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c(VContainer,{staticClass:"justify-center",attrs:{"id":"register","tag":"section"}},[_c('div',{staticClass:"text-center",staticStyle:{"width":"100%"}},[_c('img',{attrs:{"src":require("../../../assets/dealboard-logo-black.svg"),"alt":"Dealboard"}})]),_c('p',{staticClass:"text-center mt-5"},[_vm._v(_vm._s(_vm.$t("cvm_required")))]),_c(VRow,{staticClass:"mb-4 mx-1",attrs:{"justify":"center","align":"center"}},[_c(VSlideYTransition,{attrs:{"appear":""}},[_c(VCard,{staticClass:"SecondCadasterCard px-5 py-3",staticStyle:{"border-style":"groove"},attrs:{"light":"","max-width":"100%","width":"1000"}},[_c(VCardText,{staticClass:"text-center"},[(_vm.loading)?_c(VRow,{attrs:{"justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"70","color":"primary"}})],1):(
              _vm.step == _vm.ComplienceStepsPFEnum.REGISTEERINFO &&
              _vm.user.JuridicalType == _vm.JuridicalTypeEnum.NaturalPerson
            )?_c('RegisterInfo',{attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.previous_step}}):(
              _vm.step == _vm.ComplienceStepsPFEnum.PERSONALDOCS &&
              _vm.user.JuridicalType == _vm.JuridicalTypeEnum.NaturalPerson
            )?_c('PersonalDocs',{attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.previous_step}}):(
              _vm.step == _vm.ComplienceStepsPFEnum.CONTACTS &&
              _vm.user.JuridicalType == _vm.JuridicalTypeEnum.NaturalPerson
            )?_c('Contacts',{attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.previous_step}}):(
              _vm.step == _vm.ComplienceStepsPFEnum.PROFESSIONALINFO &&
              _vm.user.JuridicalType == _vm.JuridicalTypeEnum.NaturalPerson
            )?_c('ProfessionalInfo',{attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.previous_step}}):(
              _vm.step == _vm.ComplienceStepsPFEnum.PATRIMONIALINFO &&
              _vm.user.JuridicalType == _vm.JuridicalTypeEnum.NaturalPerson
            )?_c('PatrimonialInfo',{attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.previous_step}}):(
              _vm.step == _vm.ComplienceStepsPFEnum.STATEMENTS &&
              _vm.user.JuridicalType == _vm.JuridicalTypeEnum.NaturalPerson
            )?_c('Statements',{attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.previous_step}}):(
              (_vm.step == _vm.ComplienceStepsPFEnum.BANKINFO &&
                _vm.user.JuridicalType == _vm.JuridicalTypeEnum.NaturalPerson) ||
              (_vm.step == _vm.ComplienceStepsPJEnum.BANKINFO &&
                _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson)
            )?_c('BankInfo',{attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.previous_step}}):(
              _vm.step == _vm.ComplienceStepsPJEnum.JURIDICALINFO &&
              _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson
            )?_c('JuridicalInfo',{attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.previous_step}}):(
              _vm.step == _vm.ComplienceStepsPJEnum.LEGALREPRESENTATIVEINFO &&
              _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson
            )?_c('LegalRepresentativeInfo',{attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.previous_step}}):(
              _vm.step == _vm.ComplienceStepsPJEnum.NONRESIDENTINVESTORINFO &&
              _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson &&
              _vm.user.HasNonResidentInvestor
            )?_c('NonResidentInvestorInfo',{attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.previous_step}}):(
              (_vm.step == _vm.ComplienceStepsPJEnum.STOCKPOSITIONRESIDENT &&
                _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson &&
                !_vm.user.HasNonResidentInvestor) ||
              (_vm.step == _vm.ComplienceStepsPJEnum.STOCKPOSITIONNONRESIDENT &&
                _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson &&
                _vm.user.HasNonResidentInvestor)
            )?_c('StockPosition',{attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.previous_step}}):(
              (_vm.step == _vm.ComplienceStepsPJEnum.EQUITYHOLDINGRESIDENT &&
                _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson &&
                !_vm.user.HasNonResidentInvestor) ||
              (_vm.step == _vm.ComplienceStepsPJEnum.EQUITYHOLDINGNONRESIDENT &&
                _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson &&
                _vm.user.HasNonResidentInvestor)
            )?_c('EquityHolding',{attrs:{"User":_vm.user},on:{"next":_vm.next_step,"back":_vm.previous_step}}):(
              ((_vm.step == _vm.ComplienceStepsPJEnum.FINALDECLARATIONSRESIDENT ||
                _vm.step == _vm.ComplienceStepsPJEnum.FINALDECLARATIONSNONRESIDENT) &&
                _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson) ||
              (_vm.step == _vm.ComplienceStepsPFEnum.FINALDECLARATIONS &&
                _vm.user.JuridicalType == _vm.JuridicalTypeEnum.NaturalPerson)
            )?_c('FinalDeclarations',{attrs:{"User":_vm.user},on:{"next":_vm.finishProfile,"back":_vm.previous_step}}):_vm._e()],1)],1)],1)],1),_c(VRow,[_c(VProgressLinear,{staticClass:"mx-auto text-center",staticStyle:{"max-width":"35%"},attrs:{"background-color":"grey","color":"primary","rounded":"","height":"7px","value":(_vm.step * 100) /
        (_vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson
          ? _vm.ComplienceStepsPJEnum.FINALDECLARATIONSRESIDENT
          : _vm.ComplienceStepsPFEnum.FINALDECLARATIONS)}})],1)],1):_c(VContainer,[_c(VProgressCircular,{staticStyle:{"margin-left":"50%"},attrs:{"indeterminate":"","size":"70","color":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }